/**
 * Mocking client-server processing
 */

 import axios from 'axios'

 export default {
 
   getMap (datos, callback) {

    //let url = 'https://tracker.helmuga.cloud/resources/livetracker/' + datos + '/map_data.json?ts=' + Date.now()
    let url = 'https://resources.helmuga.cloud/livetracker/' + datos + '/map_data.json?ts=' + Date.now()
         
    axios.get(url)
    .then(response => { 
      callback(response.data)
    })

   }
 }
 