<template>
  <div class="loading" v-if="!['tv', 'map', 'profile', 'situation', 'dashboard1', 'intermediate', 'resultswidget'].includes(this.pages.getPageVisible())" v-show="this.pages.getLoading()">
    <div class="top">
      <div class="skeleton-menu-desktop">
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
      </div>      
      <div class="skeleton-menu-mobile">
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton-container-text">
          <div class="skeleton skeleton-text" style="width: 150px; height: 13px;"></div>
          <div class="skeleton skeleton-text" style="width: 150px; height: 10px;"></div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="skeleton-situation">
        <div class="skeleton-title">
          <div class="skeleton skeleton-text" style="width: 100px; height: 15px;"></div>
        </div>
        <div class="skeleton skeleton-bloque" style="height: 50px;"></div>
        <div class="skeleton skeleton-bloque" style="height: 100px;"></div>
        <div class="skeleton-title">
          <div class="skeleton skeleton-text" style="width: 100px; height: 15px;"></div>
        </div>
        <div class="skeleton skeleton-bloque" style="height: 20px;"></div>
        <div class="skeleton skeleton-bloque" style="height: 20px;"></div>
        <div class="skeleton skeleton-bloque" style="height: 20px;"></div>
        <div class="skeleton skeleton-bloque" style="height: 20px;"></div>
        <div class="skeleton-title">
          <div class="skeleton skeleton-text" style="width: 100px; height: 15px;"></div>
        </div>
        <div class="skeleton skeleton-bloque" style="height: 200px;"></div>
      </div>
      <div class="skeleton-right">
        <div class="skeleton skeleton-right-top"></div>
        <div class="skeleton-right-bottom">
          <div class="skeleton skeleton-right-bottom-left"></div>
          <div class="skeleton skeleton-right-bottom-right"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="skeleton-container-icons">
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
        <div class="skeleton skeleton-icon"></div>
      </div>
    </div>
  </div>
  <div v-if="this.pages.getPageVisible() === 'tv'" ref="tv">
    <LayoutMain />
  </div>
  <div v-if="this.pages.getPageVisible() === 'profile'">
    <Profile 
      style="width: 100%; height: 100vh;"
      v-if="this.map.getProfileData().length > 0"
      ref="perfil" />
  </div>
  <div v-else-if="this.pages.getPageVisible() === 'map'">
    <Map 
      style="width: 100%; height: 100vh;"
      v-if="this.map.getProfileData().length > 0"
      ref="map" />
  </div>
  <div v-else-if="this.pages.getPageVisible() === 'situation'">
    <SituationMain 
      style="width: 100%;" />
  </div>
  <div v-else-if="this.pages.getPageVisible() === 'dashboard1'">
    <LayoutDashboard1 
      ref="dashboard1"
      style="width: 100%;" />
  </div>  
  <div v-else-if="this.pages.getPageVisible() === 'intermediate'">
    <IntermediateMain 
      ref="intermediate"
      style="width: 100%;" />
  </div>
  <div v-else-if="this.pages.getPageVisible() === 'resultswidget'">    
    <div class="container_resultswidget" v-if="this.pages.getCategory() != ''">
      <ResultsWidget 
        ref="resultswidget"
        style="width: 100%;" />
    </div>
    <div class="container_resultswidget" v-if="this.pages.getCategory() == ''">
      <ResultsWidget
        ref="resultswidget"
        style="width: 100%;"
        v-for="(final, index) in this.config.getClassifications().filter((item) => item.tip >= 0)"
        :key="index"
        :final="final"
      ></ResultsWidget>
    </div>
  </div>
  <div v-else class="page-content-wrapper">
    <div class="top">
      <Menu></Menu>
    </div>
    <div class="middle" :style="getRaceThumbBackground">
      <Popup v-if="this.pages.getVisiblePopup()" />
      <LayoutDashboard ref="dashboard" class='main_container' :style="setPosition('dashboard')" />
      <LayoutStartlist class='main_container' :style="setPosition('startlist')" />
      <LayoutResults class='main_container' :style="setPosition('results')" />
      <LayoutCommentary class='main_container' :style="setPosition('commentary')" />
      <LayoutTracker v-if="tieneTracker()" class='main_container' :style="setPosition('tracker')" />
      <LayoutInfo class='main_container' :style="setPosition('info')" />
      <LayoutCustom class='main_container' :style="setPosition('custom')" />
    </div>
    <div class="bottom">
      <MenuBottom></MenuBottom>
    </div>
    <div v-if="this.pages.getImageDetail() !== ''" class="image_detail">
      <ImageDetail></ImageDetail>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import MenuBottom from '@/components/MenuBottom.vue'
import LayoutDashboard from '@/layouts/LayoutDashboard.vue'
import LayoutDashboard1 from '@/layouts/LayoutDashboard1.vue'
import LayoutStartlist from '@/layouts/LayoutStartlist.vue'
import LayoutResults from '@/layouts/LayoutResults.vue'
import LayoutCommentary from '@/layouts/LayoutCommentary.vue'
import LayoutTracker from '@/layouts/LayoutTracker.vue'
import LayoutInfo from '@/layouts/LayoutInfo.vue'
import LayoutCustom from '@/layouts/LayoutCustom.vue'
import LayoutMain from '@/components/tv/LayoutMain.vue'
import Profile from '@/components/Profile.vue'
import Map from '@/components/Map.vue'
import Popup from '@/components/Popup.vue'
import ImageDetail from '@/components/ImageDetail.vue'
import SituationMain from '@/components/SituationMain.vue'
import IntermediateMain from '@/components/IntermediateMain.vue'
import ResultsWidget from '@/components/ResultsWidget.vue'
import { usePagesStore } from '@/store/pages'
import { useConfigStore } from '@/store/config'
import { useStartlistStore } from '@/store/startlist'
import { useUpdateStore } from '@/store/update'
import { useMapStore } from '@/store/map'
import { useTimetableStore } from '@/store/timetable'
import { useResultsStore } from '@/store/results'

export default {
  name: 'App',

  data () {
    return {
      font: 'Premier',
      backgroundcolor: this.config.getConfig().general.color1,
      backgroundcolormenu: this.config.getConfig().general.color2,
    }
  },

  setup() {
    let last_height = 0

    const pages = usePagesStore()
    const config = useConfigStore()
    const startlist = useStartlistStore()
    const update = useUpdateStore()
    const map = useMapStore()
    const timetable = useTimetableStore()
    const results = useResultsStore()

    return {
      pages,
      config,
      startlist,
      update,
      map,
      timetable,
      results
    }
  },

  components: {
    Menu,
    MenuBottom,
    LayoutDashboard,
    LayoutDashboard1,
    LayoutStartlist,
    LayoutResults,
    LayoutCommentary,
    LayoutTracker,
    LayoutInfo,
    LayoutCustom,
    LayoutMain,
    Profile,
    Map,
    SituationMain,
    Popup,
    ImageDetail,
    IntermediateMain,
    ResultsWidget
  },

  created() {

      var queryString = ""
      var camposURL, carrera, anyo, etapa, lang, page, category
      const dominio = this.replaceAll(window.location.hostname, ".", "")

      queryString = window.location.pathname.replace("?", "").replace("livetracker/", "")

      if (!queryString.endsWith('/'))
        queryString += '/';

      camposURL = queryString.split("/")
      this.config.fetchConfigURL(dominio)
      .then((data) => {
      
        carrera = data.race
        anyo = data.year
        etapa = data.stage      

        switch(camposURL.length) {

          case 3: // https://racecenter.itzulia.eus/en/
            lang = camposURL[1]
          break

          case 4: // https://tracker.helmuga.cloud/livetracker/vuelta-a-murcia/2022/

            if(["situation", "map", "profile", "intermediate"].includes(camposURL[2])) {
              lang = camposURL[1]
              page = camposURL[2]
              category = null
            } else {
              if (carrera !== camposURL[1] && !["trackerhelmugacloud", "localhost", "livetracker-vue-clientpagesdev", "livetrackerhelmugacloud"].includes(dominio)) 
                return

              carrera = camposURL[1]
              anyo = camposURL[2]
              etapa = "NA"
              lang = null
              page = null
              category = null
            }
          break
          
          case 5: // https://tracker.helmuga.cloud/livetracker/itzulia-basque-country/2022/02/

            if(["situation", "map", "profile", "intermediate"].includes(camposURL[2])) {
              lang = camposURL[1]
              page = camposURL[2]
              category = camposURL[3]
            } else {
              if (carrera !== camposURL[1] && !["trackerhelmugacloud", "localhost", "livetracker-vue-clientpagesdev", "livetrackerhelmugacloud"].includes(dominio)) 
                return

              carrera = camposURL[1]
              anyo = camposURL[2]
              etapa = camposURL[3]
              lang = null
              page = null
              category = null
            }
          break

          case 6: // https://tracker.helmuga.cloud/livetracker/itzulia-basque-country/2022/02/en/
            if (carrera !== camposURL[1] && !["trackerhelmugacloud", "localhost", "livetracker-vue-clientpagesdev", "livetrackerhelmugacloud"].includes(dominio)) 
              return

            carrera = camposURL[1]
            anyo = camposURL[2]
            etapa = camposURL[3]
            lang = camposURL[4]
            page = null
            category = null
          break

          case 7: // https://tracker.helmuga.cloud/livetracker/itzulia-basque-country/2022/02/en/results/
            if (carrera !== camposURL[1] && !["trackerhelmugacloud", "localhost", "livetracker-vue-clientpagesdev", "livetrackerhelmugacloud"].includes(dominio)) 
              return

            carrera = camposURL[1]
            anyo = camposURL[2]
            etapa = camposURL[3]
            lang = camposURL[4]
            page = camposURL[5]
            category = null
          break

          case 8: // https://tracker.helmuga.cloud/livetracker/itzulia-basque-country/2022/02/en/results/overall/
            if (carrera !== camposURL[1] && !["trackerhelmugacloud", "localhost", "livetracker-vue-clientpagesdev", "livetrackerhelmugacloud"].includes(dominio)) 
              return

            carrera = camposURL[1]
            anyo = camposURL[2]
            etapa = camposURL[3]
            lang = camposURL[4]
            page = camposURL[5]
            category = camposURL[6]
          break
        }

        if(dominio.includes("feltet"))
          lang = "da"

        this.fetchData(carrera, anyo, etapa, lang, page, category)
      })

  },

  computed: {
    getRaceThumbBackground() {
      return {
        "background-image":  "url('" + this.config.getConfig().general.race_thumb_background + "')"
      }
    },
  },

  methods: {

    fetchData(carrera, anyo, etapa, lang, page, category) { 
      
      this.config.fetchConfig({ carrera, anyo, etapa })
        .then((data) => {
          this.config.setConfig(data)

          let datos = this.config.getConfig()
          let carrera = datos.general.race
          let anyo = datos.general.year

          if(lang == null) {
            let languages = datos.general.languages
            this.$i18n.locale = languages[0]
          } else {
            this.$i18n.locale = lang
          }

          document.title = this.config.getConfig().general.title || 'Live Tracker - helmuga.cloud'
          document.getElementById("favicon").href = this.config.getConfig().general.favicon

          if(this.config.getConfig().general.font != "")
            this.font = this.config.getConfig().general.font
          else
            this.font = "Premier"

          this.backgroundcolor = this.config.getConfig().general.color1
          this.backgroundcolormenu = this.config.getConfig().general.color2

          var page_initial

          if(page == null)
            page_initial = this.config.getConfig().general.pages[0]
          else {
            var array_pages = []
            array_pages.push(page)
            this.config.setPages(array_pages)
            page_initial = page
          }

          if(category != null) {
            this.pages.setCategory(category)
          }

          this.pages.setPageVisible(page_initial)

          switch(page_initial)
          {
            case "dashboard":
            case "dashboard1":
            case "results":
            case "tv":
            case "tracker":
            case "situation":
            case "intermediate":
            case "resultswidget":
              this.startlist.fetchStartlist({ carrera, anyo })
              .then((data) => {
                
                this.startlist.setStartlist(data)
                let datos = this.config.getConfig(data)

                this.update.fetchUpdate(datos.general.raceid)
                .then((data) => {
                  this.update.setUpdate(data)
                  this.setClassificationId()        

                  this.timetable.fetchTimetable(datos.general.raceid)
                    .then((data) => {
                      this.timetable.setTimetable(data)

                      this.map.fetchMap(datos.general.raceid)
                      .then((data) => {
                        if(data.length > 0){
                                                   
                          this.initGetUpdate()

                          if(!["situation", "dashboard1", "intermediate", "resultswidget"].includes(page_initial)) {
                            this.$refs.dashboard.initComponents()
                          }

                          if(page_initial != "situation") {
                            this.map.setMap(data)
                          }

                        }                    
                      })
                  })
                })
              })            
            break
           
            case "profile":
            case "map":
              this.update.fetchUpdate(datos.general.raceid)
              .then((data) => {
                this.update.setUpdate(data)
                this.setClassificationId()        

                this.timetable.fetchTimetable(datos.general.raceid)
                  .then((data) => {
                    this.timetable.setTimetable(data)

                    this.map.fetchMap(datos.general.raceid)
                    .then((data) => {
                      if(data.length > 0){
                        this.map.setMap(data)

                        this.initGetUpdateComponent(page_initial)

                      }                    
                    })
                })
              })         
            break
          }

          if(page_initial == "tracker")
            this.pages.setLoading(false)

      });
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    initGetUpdate() {

      const self = this
      var page_initial = this.config.getConfig().general.pages[0]
      this.updateIframeHeight()
    
      this.intervalid1 = setInterval(function(){

        const raceid = self.config.getConfig().general.raceid
        let version_old = self.update.getUpdate().general.version
        
        self.update.fetchUpdate(raceid)
        .then((data) => {
          self.update.setUpdate(data)
          
          if(!['tv', 'situation', 'dashboard1', 'intermediate', 'resultswidget'].includes(self.pages.getPageVisible()))
            self.$refs.dashboard.updateComponents()

          if(self.pages.getPageVisible() === 'dashboard1')
            self.$refs.dashboard1.updateComponents()

          let version_new = self.update.getUpdate().general.version

          if(version_old > 0 && version_new > version_old)
            window.location.reload()

          self.updateIframeHeight()
        })

      }, 10000);
      
    },

    initGetUpdateComponent(page) {
      
      const self = this
      var page_initial = this.config.getConfig().general.pages[0]
    
      this.intervalid1 = setInterval(function(){

        const raceid = self.config.getConfig().general.raceid
        let version_old = self.update.getUpdate().general.version
        
        self.update.fetchUpdate(raceid)
        .then((data) => {
          self.update.setUpdate(data)
          
          switch(page) {
            case "profile":
              self.$refs.perfil.updatePerfil()
            break

            case "map":
              self.$refs.map.updateMapa()
            break

          }
          
          let version_new = self.update.getUpdate().general.version

          if(version_old > 0 && version_new > version_old)
            window.location.reload()

        })

      }, 10000);
      
    },

    setClassificationId() {
      var tipo_carrera = this.config.getConfig().general.type
      var starttimes = this.config.getConfig().general.starttimes

      switch (tipo_carrera) {

        case "itt":
        case "ttt":
        case "popular":
        case "laps":
          var filteredArray = this.update.getResultsLiveTiming().filter(function(itm){
            return itm.raceid == -1
          })

          if(typeof filteredArray[0] !== "undefined" && filteredArray[0].riders.length > 0)
            this.results.setFilterClassification("-1")
          else {
            if(starttimes.length > 0)
              this.results.setFilterClassification("-9999")
            else
              this.results.setFilterClassification("-1")
          }
            
        break

      }      
    },

    setPosition(page) {
      if(page == this.pages.getPageVisible()) {
        return {
          "left": "0px"
        }
      }
      else {
        return {
          "left": "-30000px"
        }
      }
    },
    
    updateIframeHeight() {
      if(typeof document.getElementsByClassName('container_situationmain')[0] !== "undefined") {
        let message = { element: 'helmuga-situation', height: parseInt(document.getElementsByClassName('container_situationmain')[0].scrollHeight + 20), width: document.getElementsByClassName('container_situationmain')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      }

      if(typeof document.getElementsByClassName('container_intermediatemain')[0] !== "undefined") {
        let message = { element: 'helmuga-intermediate', height: parseInt(document.getElementsByClassName('container_intermediatemain')[0].scrollHeight + 20), width: document.getElementsByClassName('container_intermediatemain')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      }

      if(typeof document.getElementsByClassName('container_resultswidget')[0] !== "undefined") {
        let message = { element: 'helmuga-results', height: parseInt(document.getElementsByClassName('container_resultswidget')[0].scrollHeight + 20), width: document.getElementsByClassName('container_resultswidget')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      }
    },

    tieneTracker() {
      return this.config.getConfig().general.pages.includes("tracker")
    }
  }

}
</script>

<style>
  
  html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  #app {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {

    ::-webkit-scrollbar{
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb{
      background: #dddddd;
      border-radius: 9px;
    }

    ::-webkit-scrollbar-thumb:hover{
      background: #B3AFB3;
    }

    ::-webkit-scrollbar-track{
      background: #FFFFFF;
      border-radius: 9px;
      box-shadow: inset 0px 0px 0px 0px #F0F0F0;
    }

  }

</style>

<style scoped>

  @font-face {
    font-family: "Premier";
    src: url("~@/assets/fonts/PremierLeagueW01-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Montserrat";
    src: url("~@/assets/fonts/Montserrat-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Lato";
    src: url("~@/assets/fonts/lato-regular.woff2") format("woff2");
  }

  

  @media (max-width: 768px) {
    .startlist {
      width: 100%;
    }

    .middle {
      height: calc(100% - 50px) !important;
      min-height: calc(100% - 102px);
      background-image: unset !important;
    }

    .top {
      background: #fafafa !important;
    }

    .bottom {
      display: flex !important;
      background: v-bind(backgroundcolormenu) !important;
      /*background: linear-gradient(180deg, v-bind(backgroundcolor) 0%, rgba(255,255,255,1) 300%) !important;*/
    }

    .loading { 
      height: calc(100% - 50px) !important;
    }

    .skeleton-menu-desktop {
      display: none !important;
    }

    .skeleton-menu-mobile {
      display: flex !important;
    }

    .skeleton-situation {
      width: 100% !important;
    }
    
    .skeleton-right {
      display: none !important;
    }
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin:0;
  }

  #app > * {
    /*font-family: Premier, Montserrat, Avenir, Helvetica, Arial, sans-serif;*/
    /*font-family: Premier;*/
    font-family: v-bind(font);
  }

  .loading {
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }

  .image_detail {
    background: rgba(0, 0, 0, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .skeleton-menu-desktop {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 20px;
  }

  .skeleton-menu-mobile {
    display: none;
    align-items: center;
    height: 50px;
    padding-left: 20px;
    border-bottom: 1px solid #dbdbdb;
  }

  .skeleton-container-icons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }

  .skeleton-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 10px;
  }

  .skeleton-container-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30px;
  }

  .skeleton-situation {    
    width: 33%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .skeleton-right {
    width: 66%;
    display: flex;
    flex-direction: column;
  }

  .skeleton-right-top {
    width: calc(100% - 20px);
    height: 200px;
    background: #ddd;
    margin: 10px 20px;
    border-radius: 10px;
  }

  .skeleton-right-bottom {
    display: flex;
    height: calc(100% - 200px);
  }

  .skeleton-right-bottom-left {
    width: 60%;
    height: calc(100% - 80px);
    background: #ddd;
    margin: 10px 20px;
    border-radius: 10px;
  }

  .skeleton-right-bottom-right {
    width: 40%;
    height: calc(100% - 80px);
    background: #ddd;
    border-radius: 10px;
    margin: 10px 0;
  }

  .skeleton-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  .skeleton-bloque {
    width: 80%;
    margin-bottom: 10px;
    border-radius: 10px;
  }


  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .main_container {
    position: absolute;
    left: -30000px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .page-content-wrapper {
    display: flex; 
    flex-direction: column;
    height: 100%;
  }

  .top {
    height: 50px;
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: v-bind(backgroundcolormenu);
    /*background: linear-gradient(90deg, v-bind(backgroundcolor) 0%, rgba(255,255,255,1) 100%);*/
  }

  .middle {
    /*display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;*/
    
    background-repeat: no-repeat;
    background-position: 99% 98%;
    background-size: 200px;
    
    margin-top: 50px;

    height: 100%;
    position: relative;
    display: flex;
  }

  .bottom {
    display: none;
    height: 50px;
    flex-shrink: 0;
    background: #fafafa;
    border-top: 1px solid #dbdbdb;
    justify-content: flex-end;
  }

  .startlist {
    height: 100%;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    margin: 10px 0;
  }

  .resizable-content {
    height: 100%;
    width: 100%;
    background-color: aqua;
  }

</style>

  
